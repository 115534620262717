.corona-widget-header-toolbar-bttn {
    background: #2D3595;
    color: white;
    border: 0;
    height: 100%;
    width: 40px;
    border-left: 1px solid #4f56a9;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    outline: medium none;
    text-align: center;
    padding: 0;
}

.corona-widget-header-toolbar-bttn:hover {
    background: #1a2182;
}

.corona-widget-header-toolbar-bttn-icon {
    font-size: 20px;
    transition: all 0.2s ease-in-out;
}

.corona-widget-header-toolbar-bttn.corona-not-toggled .corona-widget-header-toolbar-bttn-icon {
    transform: rotate(180deg);
}