.corona-infected-list-elem-avatar {
    display: block;
    width: 100%;
    height: 100%;
}

.corona-infected-list-elem-top-section-wrap {
    display: flex;
}

.corona-infected-list-elem {
    padding-bottom: 5px;
    margin-bottom: 10px;
    font-size: 12px;
    border-bottom: 1px solid #eaeaea;
}

.corona-infected-list-elem:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}

.cornoa-infectedlist-elem-source-separator {
    margin: 0 3px;
}

.corona-infected-list-elem-top-section-right {
    flex: 1;
}

.corona-infected-list-elem-top-section-upper {
    display: flex;
    justify-content: space-between;
}

.corona-infected-list-elem-top-section-mid {
    display: flex;
    justify-content: space-between;
}

.corona-infected-list-elem-avatar-wrap {
    position: relative;
    overflow: hidden;
    margin-right: 5px;
    height: 35px;
    width: 35px;
}

.corona-infected-list-elem-avatar-counter {
    font-size: 10px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}

.corona-infected-list-elem-top-section-mid {
    display: flex;
    justify-content: space-between;
}

.corona-infected-list-elem-link-bttn {
    background: black;
    border: 1px solid black;
    color: white;
}

.corona-infected-list-elem-date-reported {
    font-size: 10px;
}

.corona-infected-list {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.corona-infected-list-scroller {
    flex: 1;
    overflow: auto;
    padding: 5px;
}

.corona-infected-list-header {
    padding: 5px;
    border-bottom: 1px solid #eaeaea;
}

.corona-infected-list-footer {
    border-top: 1px solid #eaeaea;
}

.corona-infected-list-elem-infector-count {
    font-size: 10px;
}

.corona-infected-list-paginator {
    display: flex;
    border-bottom: 1px solid #eaeaea;
}

.corona-infected-list-paginator-bttn {
    width: 50%;
    text-align: center;
    font-size: 14px;
    padding: 3px 0;
    cursor: pointer;
    transition: all 0.2s linear;
}

.corona-infected-list-paginator-bttn:hover,
.corona-infected-list-paginator-bttn:active {
    color: rgb(0, 120, 212);
    background-color: #fdfdfd;
}



.corona-infected-list-paginator-bttn:first-child {
    border-right: 1px solid #eaeaea;
}

.corona-infected-list-paginator-bttn[data-enabled="false"] {
    pointer-events: none !important;
}

.corona-infected-list-paginator-bttn[data-enabled="false"] * {
    pointer-events: none !important;
}

.corona-infected-list-paginator-bttn[data-enabled="false"] i {
    opacity: 0.3 !important;
}

.corona-infected-list-footer-counters {
    display: flex;
    justify-content: center;
    padding: 1px 0;
    font-size: 12px;
}