.corona-widget-wrap {
    margin-bottom: 20px;
    position: relative;
}

.corona-widget-header {
    display: flex;
    background: #2D3595;
    color: white;
}

.corona-widget-header-title {
    padding: 10px;
    font-size: 18px;
    flex: 1;
}

.corona-widget-header-toolbar {
    display: flex;
}

.corona-widget-body {
    border-left: 3px solid #2d3595;
    border-bottom: 3px solid #2d3595;
    border-right: 3px solid #2d3595;
    padding: 10px;
    max-height: 5000px;
    overflow: auto;
    transition: max-height 0.2s linear, opacity 0.2s linear;
}

.corona-widget-wrap.corona-widget-wrap-not-toggled .corona-widget-body {
    overflow: hidden !important;
    max-height: 0 !important;
    opacity: 0;
}

.corona-widget-body[data-shown="false"] {
    display: none !important;
}