.corona-infected-map-tooltip {
    position: absolute;
    background: white;
    border: 2px solid #000;
    padding: 10px;
    pointer-events: none;
}

.corona-infected-map-tooltip.hidden {
    display: none !important;
}

.corona-infected-map-tooltip-container-outer {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 401;
}

.corona-infected-map-tooltip-container {
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 130px;
    min-height: 32px;
    border-width: 2px;
    border-style: solid;
    border-color: #333;
    opacity: 0.5;
    transition: opacity 0.2s, border-color 0.2s, min-width 0.2s, min-height 0.2s;
}

.corona-infected-map-tooltip-label {
    font-size: 12px;
}

.corona-infected-map-tooltip-container-unknown {
    margin-top: 10px;
    background: white;
    border-width: 2px;
    border-style: solid;
    border-color: #333;
    text-align: center;
}

.corona-infected-map-tooltip-container-unknown.is-hidden {
    display: none !important;
}

.corona-infected-map-tooltip-container.has-selected-county {
    justify-content: left;
    text-indent: 10px;
    opacity: 1;
    min-width: 145px;
    min-height: 56px;
}

.corona-infected-map-wrap {
    position: relative;
}

.corona-infected-slider-wrap {
    padding: 0px 5px 1px 15px;
    text-align: center;
    border-top: 1px solid #eaeaea;
    position: relative;
}

.corona-infected-slider-date {
    position: absolute;
    top: -20px;
    z-index: 401;
    right: 0;
    background: white;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 0 5px;
    border-top: 1px solid #eaeaea;
    border-left: 1px solid #eaeaea;
    pointer-events: none;
}