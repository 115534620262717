.corona-infected-list-elem-description {
    font-size: 10px;
    margin-top: 4px;
    line-height: 14px;
}

.corona-infected-list-elem-description[data-shown="false"] {
    display: none;
}

.corona-infected-list-elem-footer {
    font-size: 10px;
    margin-top: 3px;
    position: relative;
    padding-right: 25px;
}

.corona-infected-list-elem-desc-toggler {
    font-size: 17px;
    position: absolute;
    right: 0;
    bottom: 0;
    line-height: 10px;
    cursor: pointer;
    outline: medium none;
}