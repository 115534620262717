.corona-app-navigator-wrap {
  z-index: 401;
  top: 5px;
  left: 5px;
  position: absolute;
}

.corona-app-navigator-toggler {
  background: #fff;
  cursor: pointer;
  border: 2px solid #000;
  position: relative;
  height: 45px;
  width: 45px;
  font-size: 26px;
}

.corona-app-navigator-list-wrap {
  transition: all 0.2s ease-in-out;
  position: absolute;
  background: #fff;
  top: 40px;
  left: 0;
  border: 2px solid #000;
  opacity: 0;
}

.corona-app-navigator-isHidden .corona-app-navigator-list-wrap {
  pointer-events: none;
}

.corona-app-navigator-isActive .corona-app-navigator-list-wrap {
  transform: translateY(10px);
  opacity: 1;
}

.corona-app-navigator-toggler-icon-burger,
.corona-app-navigator-toggler-icon-close {
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 11px;
  left: 7px;
}

.corona-app-navigator-isActive .corona-app-navigator-toggler-icon-burger {
  transform: scale(0);
}

.corona-app-navigator-isHidden .corona-app-navigator-toggler-icon-close {
  transform: scale(0);
}

.corona-app-navigator-list-item {
  position: relative;
}

.corona-app-navigator-list-item:after {
  content: " ";
  position: absolute;
  width: calc(100% - 20px);
  top: 0;
  background-color: #eaeaea;
  height: 1px;
  left: 10px;
  right: 10px;
}

.corona-app-navigator-list-item:first-child:after {
  content: " ";
  display: none;
}

.corona-app-navigator-list-item a {
  color: #000;
  padding: 10px 25px;
  display: block;
  transition: all 0.2s linear;
}

.corona-app-navigator-list-item:hover a {
  background-color: #eaeaea;
}

.corona-app-navigator-list-item:hover+.corona-app-navigator-list-item:after {
  content: " ";
  display: none;
}