body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .leaflet-container {
  height: 100%;
  width: 100%;
} */

.corona-infected-map-leaflet {
  height: 100%;
  width: 100%;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

.corona-app-container {
  height: 100%;
  position: relative;
}

.corona-page-home-wrap {
  height: 100%;
  display: flex;
}

.corona-page-home-wrap .corona-infected-map-wrap {
  height: 100%;
}

.corona-page-home-wrap .corona-page-home-infected-map-wrap {
  height: 100%;
  flex: 1;
  border-right: 2px solid #eaeaea;
}

.corona-page-menu-padded {
  margin-left: 60px;
}

.corona-infected-map-wrap {
  display: flex;
  flex-direction: column;
}

.corona-infected-map-mapwrap {
  flex: 1;
  /* background-color: red; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.corona-infected-map-svg {
  /* width: 100%;
  height: 100%; */

  /* background-color: yellow; */
  width: 800px;
  height: 800px;
}

.corona-page-home-caselist-wrap {
  width: 250px;
}

@media only screen and (max-width: 675px) {
  .corona-page-home-wrap {
    flex-direction: column;
  }

  .corona-page-home-wrap .corona-page-home-infected-map-wrap {
    border-right: 0;
    border-bottom: 2px solid #eaeaea;
    height: 55%;
  }

  .corona-page-home-caselist-wrap {
    height: 45%;
    width: 100%;
  }

  #root .corona-app-navigator-toggler {
    height: 35px;
    width: 35px;
    font-size: 14px;
  }

  #root .corona-app-navigator-toggler-icon-burger,
  #root .corona-app-navigator-toggler-icon-close {
    top: 6px;
    left: 8px;
  }

  #root .corona-app-navigator-list-wrap {
    top: 28px;
  }

  body .crona-page-charts-row {
    flex-direction: column;
  }

  body .crona-page-charts-row-double {
    height: auto;
  }

  body .crona-page-charts-row-double .corona-line-chart-wrap {
    height: 400px;
    width: 100%;
    /* flex: 1; */
  }
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}