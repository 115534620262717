.corona-page-charts {
    overflow: auto;
    height: 100%;
    background-color: #f7f7f7;
    /* background-image: url("https://i.imgur.com/m5MedCR.png"); */
    /* background-image: url("https://image.freepik.com/free-vector/cute-soft-color-subtle-pattern-background_1017-14890.jpg"); */
}

.corona-page-charts-title {
    margin-left: 60px;
    text-align: center;
    text-indent: -60px;
    position: relative;
}

.corona-page-charts .corona-page-charts-title {
    margin-top: 10px;
    margin-bottom: 20px;
}

.corona-page-charts .corona-page-charts-title:after {
    content: "";
    background: #eaeaea;
    height: 1px;
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: -4px;
}

.corona-page-charts-contentwrap {
    /* margin-left: 10px;
    margin-right: 10px; */
    max-width: 1260px;
    margin: 0 auto;
    padding: 0px 5px;
}

.crona-page-charts-row {
    height: 400px;
    display: flex;
}

.crona-page-charts-row-single .corona-line-chart-wrap {
    height: 100%;
    width: 100%;
}

.crona-page-charts-row-double .corona-line-chart-wrap {
    height: 100%;
    width: 50%;
}