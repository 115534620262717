.corona-widget-preloader-wrap {
    position: absolute;
    background: rgba(255, 255, 255, 0.7);
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 100px;
    transition: transform 0.2s linear, opacity 0.2s linear;
}

.corona-widget-preloader-wrap-inner {
    flex: 1;
}

.corona-widget-preloader-wrap.corona-preloader-not-visible {
    transform: scale(0.8) !important;
    opacity: 0;
}

.corona-widget-preloader-wrap[data-shown="false"] {
    display: none !important;
}