.corona-infected-map-shimmer-wrap {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.corona-infected-map-shimmer-inner {
   flex: 1;
   padding: 0 10%;
}